import React from 'react'
import PropTypes from 'prop-types'

import {graphql} from 'gatsby'
import Helmet from 'react-helmet'
import {withNamespaces} from 'react-i18next'

import Layout from 'components/Layout'
import {DeviceModelSummaryList, DeviceModelSummaryListHeader} from 'components/fleerp/DeviceModel'

export const DeviceManufacturerTemplate = ({t, data, navigate, location}) => {
  const models = []

  data.jsonFiles.edges.map(({node}) => {
    models.push({
      href: `/${node.relativeDirectory.replace('models', node.name)}`,
      ...node.childModelsJson
    })
  })

  data.imageFiles.edges.map(({node}, i) => {
    models[i].image = node.childImageSharp.fixed
  })

  return (
    <Layout breadcrumb navigate={navigate} location={location}>
      <Helmet
        title={`${data.manufacturer.name} - ${t('fleerp.DeviceManufacturerTemplate.meta.title')}`}
        meta={[{name: 'keywords', content: data.manufacturer.name}]}
      />
      <DeviceModelSummaryListHeader />
      <DeviceModelSummaryList models={models} />
    </Layout>
  )
}

DeviceManufacturerTemplate.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}

export const query = graphql`
  query($slug: String!, $directory: String!) {
    jsonFiles: allFile(filter: {relativeDirectory: {eq: $slug}, extension: {eq: "json"}}, sort: {fields: name}) {
      edges {
        node {
          name
          relativeDirectory
          childModelsJson {
            name
            summary
          }
        }
      }
    }
    imageFiles: allFile(filter: {relativeDirectory: {eq: $slug}, extension: {eq: "png"}}, sort: {fields: name}) {
      edges {
        node {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    }
    manufacturer: manufacturersJson (directory: {eq: $directory}) {
      name
    }
  }
`

export default withNamespaces()(DeviceManufacturerTemplate)
